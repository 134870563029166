@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: 'Inter', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 300;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:disabled {
  cursor: default;
  opacity: 0.5;
}

section {
  max-width: 1100px;
  margin: 0 auto;
}

.foot-logo {
  padding: 20px;
  margin: 0;
}


@media (max-width: 740px) {

  .foot-logo {
    padding: 20px;
    margin: 10px auto;
  }

}

@layer base {
  li {
    @apply p-4;
    font-size: 13px;

    color: #fff;
  }

  button {
    @apply: text-white border bg-indigo-600 border-indigo-600 hover: bg-transparent hover text-indigo-600 rounded-md
  }

  ul {
    padding: 0;
    list-style-type: none;
  }

  Footer h2 {
    padding-left: 16px;
  }

  .line-break {
    white-space: pre-wrap;
  }

  p {
    font-family: 'Roboto';
  }

  h1,
  h2,
  h3 {
    font-family: 'DM Serif Display';
    letter-spacing: 0.01em !important;
    color: #433f3c;

  }

  footer h2 {
    font-family: 'Inter';

  }


}